<template>
  <div class="mt-3 p-2">
    <b-container fluid class="mb-2">
      <b-row>
        <b-col md="6">
          <h5>Booking Details</h5>
          <b-card class="farm-retreats-details border-0">
            <b-card-text>
              <table class="table table-sm">
                <tbody>
                  <tr>
                    <th>Project</th>
                    <td>{{ bookingDetails._projectID.name }}</td>
                  </tr>
                  <tr>
                    <th>Room</th>
                    <td>{{ bookingDetails._RoomID.name }}</td>
                  </tr>
                  <tr>
                    <th>Check In Date</th>
                    <td>
                      {{
                        bookingDetails.checkInDate
                          | moment("dddd, MMMM Do YYYY")
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>Check Out Date</th>
                    <td>
                      {{
                        bookingDetails.checkOutDate
                          | moment("dddd, MMMM Do YYYY")
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>Day(s) & Night(s)</th>
                    <td>
                      {{ bookingDetails.days }} Day(s)
                      {{ bookingDetails.nights }} Night(s)
                    </td>
                  </tr>
                  <tr>
                    <th>Credits</th>
                    <td>{{ bookingDetails.Price }} Credits</td>
                  </tr>
                  <tr>
                    <th>Adult(s)</th>
                    <td>{{ bookingDetails.Adult }}</td>
                  </tr>
                  <tr>
                    <th>Child(s)</th>
                    <td>{{ bookingDetails.Child }}</td>
                  </tr>
                  <tr>
                    <th>Internal Comment</th>
                    <td>{{ bookingDetails.internal_remarks }}</td>
                  </tr>
                  <tr>
                    <th>Customer Comment</th>
                    <td>{{ bookingDetails.customer_remarks }}</td>
                  </tr>
                  <tr>
                    <th>Cottage Count</th>
                    <td>{{ bookingDetails.Rooms }}</td>
                  </tr>
                  <tr>
                    <th>Service Charge</th>
                    <td>Rs. {{ bookingDetails.service_charge }}</td>
                  </tr>
                  <tr>
                    <th>Booking Date</th>
                    <td>
                      {{
                        bookingDetails.createdAt | moment("dddd, MMMM Do YYYY")
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>
                      <b-badge
                        variant="success"
                        v-if="bookingDetails.status == true"
                        >Confirmed</b-badge
                      >
                      <b-badge
                        variant="danger"
                        v-if="bookingDetails.status == false"
                        >Cancelled</b-badge
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="6">
          <h5>User Details</h5>
          <b-card class="farm-retreats-details border-0">
            <b-card-text>
              <table class="table table-sm">
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td>{{ bookingDetails._userID.name }}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{{ bookingDetails._userID.email }}</td>
                  </tr>
                  <tr>
                    <th>Phone No.</th>
                    <td>{{ bookingDetails._userID.phone }}</td>
                  </tr>
                </tbody>
              </table>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      bookingDetails: false,
    };
  },
  mounted() {
    this.getBookingDetails();
  },
  methods: {
    getBookingDetails() {
      let id = this.$route.params.id;
      console.log(id);
      window.axios.get("/farm-resort-booking/" + id).then((resp) => {
        console.log(resp.data.data);
        this.bookingDetails = resp.data.data;
      });
    },
  },
};
</script>

<style scoped>
.farm-retreats-details .card {
  border-radius: 11px;
}

.farm-retreats-details .card-body {
  background-color: #dcffd9;
  border-radius: 11px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.table > tbody > tr:first-child > td,
.table > tbody > tr:first-child > th {
  border: none;
}
</style>
